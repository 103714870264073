define("ember-composable-helpers/helpers/append", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.append = append;
  _exports.default = void 0;
  function append([...arrays]) {
    return [].concat(...arrays);
  }
  var _default = _exports.default = (0, _helper.helper)(append);
});