define("ember-promise-helpers/helpers/promise-rejected-reason", ["exports", "ember-promise-helpers/helpers/await"], function (_exports, _await) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _await.default {
    compute(params) {
      const maybePromise = params[0];
      return this.ensureLatestPromise(maybePromise, async promise => {
        try {
          await promise;
          this.setValue(null, maybePromise);
        } catch (reason) {
          this.setValue(reason, maybePromise);
        }
      });
    }
  }
  _exports.default = _default;
});