define("ember-promise-helpers/helpers/promise-hash", ["exports", "@ember/component/helper", "rsvp"], function (_exports, _helper, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _helper.default {
    compute(params, hash) {
      return _rsvp.default.hash(hash);
    }
  }
  _exports.default = _default;
});