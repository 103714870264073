define("ember-composable-helpers/helpers/drop", ["exports", "@ember/component/helper", "ember-composable-helpers/utils/as-array"], function (_exports, _helper, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.drop = drop;
  function drop([dropAmount, array]) {
    return (0, _asArray.default)(array).slice(dropAmount);
  }
  var _default = _exports.default = (0, _helper.helper)(drop);
});