define("ember-composable-helpers/helpers/reverse", ["exports", "@ember/component/helper", "@ember/array"], function (_exports, _helper, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.reverse = reverse;
  function reverse([array]) {
    if (!(0, _array.isArray)(array)) {
      return [array];
    }
    return (0, _array.A)(array).slice(0).reverse();
  }
  var _default = _exports.default = (0, _helper.helper)(reverse);
});