define("ember-composable-helpers/helpers/chunk", ["exports", "@ember/component/helper", "@ember/array", "ember-composable-helpers/utils/as-array"], function (_exports, _helper, _array, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chunk = chunk;
  _exports.default = void 0;
  const {
    max,
    ceil
  } = Math;
  function chunk(num, array) {
    let integer = parseInt(num, 10);
    let size = max(integer, 0);
    let length = 0;
    if ((0, _array.isArray)(array)) {
      length = array.length;
    }
    array = (0, _asArray.default)(array);
    if (!length || size < 1) {
      return [];
    } else {
      let index = 0;
      let resultIndex = -1;
      let result = new Array(ceil(length / size));
      while (index < length) {
        result[++resultIndex] = array.slice(index, index += size);
      }
      return result;
    }
  }
  var _default = _exports.default = (0, _helper.helper)(function ([num, array]) {
    return chunk(num, array);
  });
});