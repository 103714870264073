define("ember-power-select/components/power-select/placeholder", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @isMutlipleWithSearch}}
    <@inputComponent @isDefaultPlaceholder={{true}} />
  {{else if @placeholder}}
    <span
      class="ember-power-select-placeholder"
      ...attributes
    >{{@placeholder}}</span>
  {{/if}}
  */
  {
    "id": "+52Nvs0m",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[30,2],null,[[\"@isDefaultPlaceholder\"],[true]],null],[1,\"\\n\"]],[]],[[[41,[30,3],[[[1,\"  \"],[11,1],[24,0,\"ember-power-select-placeholder\"],[17,4],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]],null]],[]]]],[\"@isMutlipleWithSearch\",\"@inputComponent\",\"@placeholder\",\"&attrs\"],false,[\"if\"]]",
    "moduleName": "ember-power-select/components/power-select/placeholder.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});