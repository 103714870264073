define("@ember-data/canary-features/index", ["exports", "@ember-data/canary-features/default-features"], function (_exports, _defaultFeatures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SAMPLE_FEATURE_FLAG = _exports.FEATURES = void 0;
  /* globals EmberDataENV */

  const ENV = typeof EmberDataENV !== 'undefined' && EmberDataENV !== null ? EmberDataENV : {};
  function featureValue(value) {
    if (ENV.ENABLE_OPTIONAL_FEATURES && value === null) {
      return true;
    }
    return value;
  }
  const FEATURES = _exports.FEATURES = Object.assign({}, _defaultFeatures.default, ENV.FEATURES);
  const SAMPLE_FEATURE_FLAG = _exports.SAMPLE_FEATURE_FLAG = featureValue(FEATURES.SAMPLE_FEATURE_FLAG);
});