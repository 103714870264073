define("ember-concurrency/-private/external/scheduler/scheduler", ["exports", "ember-concurrency/-private/external/scheduler/refresh", "ember-concurrency/-private/external/scheduler/state-tracker/state-tracker", "ember-concurrency/-private/external/scheduler/state-tracker/null-state-tracker"], function (_exports, _refresh, _stateTracker, _nullStateTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Scheduler base class

  // When a Task is performed, it creates an unstarted TaskInstance and
  // passes it to the Scheduler to determine when it should run. The
  // scheduler consults the schedulerPolicy (e.g. DropPolicy, RestartablePolicy, etc)
  // to determine whether the task instance should start executing, be enqueued
  // for later execution, or be immediately cancelled. As TaskInstances start
  // and run to completion, the Scheduler's `refresh()` method is called to
  // give it an opportunity to start (or cancel) previously enqueued task instances,
  // as well as update the derived state on Tasks and TaskGroups.

  // Every Task has its own Scheduler instance, unless it is part of a group,
  // in which case all the Tasks in a group share a single Scheduler.
  class Scheduler {
    constructor(schedulerPolicy, stateTrackingEnabled) {
      this.schedulerPolicy = schedulerPolicy;
      this.stateTrackingEnabled = stateTrackingEnabled;
      this.taskInstances = [];
    }
    cancelAll(guid, cancelRequest) {
      let cancelations = this.taskInstances.map(taskInstance => {
        if (taskInstance.task.guids[guid]) {
          taskInstance.executor.cancel(cancelRequest);
        }
      }).filter(cancelation => !!cancelation);
      return Promise.all(cancelations);
    }
    perform(taskInstance) {
      taskInstance.onFinalize(() => this.scheduleRefresh());
      this.taskInstances.push(taskInstance);
      this.refresh();
    }
    scheduleRefresh() {
      Promise.resolve().then(() => this.refresh());
    }
    refresh() {
      let stateTracker = this.stateTrackingEnabled ? new _stateTracker.default() : new _nullStateTracker.default();
      let refresh = new _refresh.default(this.schedulerPolicy, stateTracker, this.taskInstances);
      this.taskInstances = refresh.process();
    }
  }
  var _default = _exports.default = Scheduler;
});