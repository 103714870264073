define("ember-composable-helpers/helpers/optional", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.optional = optional;
  function optional([action]) {
    if (typeof action === 'function') {
      return action;
    }
    return i => i;
  }
  var _default = _exports.default = (0, _helper.helper)(optional);
});