define("ember-composable-helpers/helpers/object-at", ["exports", "@ember/component/helper", "@ember/array"], function (_exports, _helper, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.objectAt = objectAt;
  function objectAt(index, array) {
    if (!(0, _array.isArray)(array)) {
      return undefined;
    }
    index = parseInt(index, 10);
    return (0, _array.A)(array).objectAt(index);
  }
  var _default = _exports.default = (0, _helper.helper)(function ([index, array]) {
    return objectAt(index, array);
  });
});