define("@ember-data/private-build-infra/available-packages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    HAS_EMBER_DATA_PACKAGE: 'ember-data',
    HAS_STORE_PACKAGE: '@ember-data/store',
    HAS_MODEL_PACKAGE: '@ember-data/model',
    HAS_RECORD_DATA_PACKAGE: '@ember-data/record-data',
    HAS_ADAPTER_PACKAGE: '@ember-data/adapter',
    HAS_SERIALIZER_PACKAGE: '@ember-data/serializer',
    HAS_DEBUG_PACKAGE: '@ember-data/debug'
  };
});