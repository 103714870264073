define("ember-concurrency/helpers/perform", ["exports", "@ember/component/helper", "@ember/debug", "ember-concurrency/-private/helpers"], function (_exports, _helper, _debug, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.performHelper = performHelper;
  function maybeReportError(onError) {
    return function (e) {
      if (typeof onError === 'function') {
        onError(e);
      } else if (onError === null) {
        // Do nothing
      } else {
        (true && !(false) && (0, _debug.assert)(`The onError argument passed to the \`perform\` helper should be a function or null; you passed ${onError}`, false));
      }
    };
  }
  function performHelper(args, hash) {
    let perform = (0, _helpers.taskHelperClosure)('perform', 'perform', args, hash);
    if (hash && typeof hash.onError !== 'undefined') {
      return function (...innerArgs) {
        try {
          let taskInstance = perform(...innerArgs);
          return taskInstance.catch(maybeReportError(hash.onError));
          // eslint-disable-next-line no-empty
        } catch {
          maybeReportError(hash.onError);
        }
      };
    } else {
      return perform;
    }
  }
  var _default = _exports.default = (0, _helper.helper)(performHelper);
});